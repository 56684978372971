import { CompositeLayer, FlyToInterpolator } from "@deck.gl/core";
import { IconLayer } from "@deck.gl/layers";

import { shuffle } from "@/utils/random";
import { createPlateTextLayer } from "@/components/layers/PlateTextLayer";
import { occasionMarkIconMapping } from "@/assets/icons/occasions-sprite";
import {
  occasionPlatePoints,
  occasionPlateSettings,
  occasionTransitionDuration,
  getOccasionPlateZ,
  buildOccasionPlateData,
} from "@/helpers/occasions";

const offsetSettings = {
  icon: [-107, 19],
  title: [0, 0],
  context: {
    title: [0, 30],
    text: [72, 28],
  },
  need: {
    title: [0, 52],
    text: [42, 50],
  },
  choiceDriver: {
    title: [0, 74],
    text: [114, 72],
  },
};

export const createPlateLayer = async (ctx) => {
  class PlateLayer extends CompositeLayer {
    renderLayers() {
      return [
        new IconLayer({
          id: "icon-layer",
          data: this.props.data,
          pickable: true,
          opacity: this.props.opacity,
          iconAtlas: require("/src/assets/icons/occasion-mark-sprite.svg"),
          iconMapping: occasionMarkIconMapping,
          getIcon: this.props.getIcon,
          billboard: true,
          sizeScale: 5,
          getPosition: this.props.getPosition,
          getSize: (d) => {
            return this.props.getShowNow(d) ? 38 : 0;
          },
          getColor: this.props.getColor,
          getPixelOffset: [-107, 19],
          updateTriggers: {
            getColor: this.props.updateTriggers.getColor,
            opacity: this.props.updateTriggers.getColor,
            getIcon: this.props.updateTriggers.getColor,
            getSize: this.props.updateTriggers.getColor,
          },
        }),
        createPlateTextLayer({ ctx: this, id: "title", size: 24, offset: offsetSettings.title }),
        createPlateTextLayer({
          ctx: this,
          id: "context-title",
          title: "CONTEXT",
          size: 14,
          offset: offsetSettings.context.title,
        }),
        createPlateTextLayer({
          ctx: this,
          id: "context",
          size: 19,
          text: "getTextContext",
          offset: offsetSettings.context.text,
        }),
        createPlateTextLayer({
          ctx: this,
          id: "needStates-title",
          title: "NEED",
          size: 14,
          offset: offsetSettings.need.title,
        }),
        createPlateTextLayer({
          ctx: this,
          id: "needStates",
          size: 19,
          text: "getTextNeedStates",
          offset: offsetSettings.need.text,
        }),
        createPlateTextLayer({
          ctx: this,
          id: "choiceDrivers-title",
          title: "CHOICE DRIVERS",
          size: 14,
          offset: offsetSettings.choiceDriver.title,
        }),
        createPlateTextLayer({
          ctx: this,
          id: "choiceDrivers",
          size: 19,
          text: "getTextChoiceDrivers",
          offset: offsetSettings.choiceDriver.text,
        }),
      ];
    }
  }

  PlateLayer.layerName = "PlateLayer";

  PlateLayer.defaultProps = {
    fontFamily: "Urbanist, sans-serif",
    getTextAnchor: "start",
    getAlignmentBaseline: "center",
    billboard: true,
    pickable: true,

    getPosition: {
      type: "accessor",
      value: (d) => [d.long, d.lat, getOccasionPlateZ()],
    },

    getColor: {
      type: "accessor",
      value: (d) => {
        ctx.occasionPlateStates[d.id].isShow =
          d.long <= ctx.viewState.longitude + occasionPlateSettings.longOffsetTo &&
          d.long >= ctx.viewState.longitude - occasionPlateSettings.longOffsetFrom &&
          d.lat <= ctx.viewState.latitude + occasionPlateSettings.latOffsetTo &&
          d.lat >= ctx.viewState.latitude - occasionPlateSettings.latOffsetFrom;
        const { opacity, isShow } = ctx.occasionPlateStates[d.id];
        const { currentOpacity } = opacity;
        const occasion = buildOccasionPlateData(ctx.filteredOccasion, ctx.citiesOccasionsData);

        if (isShow && ctx.showNow[d.id] + occasionPlateSettings.delay > Date.now() && occasion) {
          if (!ctx.occasionPlateStates[d.id].occasion) {
            ctx.occasionPlateStates[d.id].occasion = occasion;
          }

          ctx.occasionPlateStates[d.id].opacity = ctx.getOccasionOpacity({
            currentOpacity,
            directionOpacity: 0,
          });
        } else {
          const opacity = ctx.getOccasionOpacity({ currentOpacity, directionOpacity: 1 });

          if (opacity.currentOpacity === 0) {
            delete ctx.occasionPlateStates[d.id].occasion;
            delete ctx.showNow[d.id];
          }

          ctx.occasionPlateStates[d.id].opacity = opacity;
        }
        return [...occasionPlateSettings.initialColor, currentOpacity];
      },
    },

    getShowNow: {
      type: "accessor",
      value: (d) => {
        return !!ctx.occasionPlateStates?.[d.id]?.occasion;
      },
    },

    getIcon: {
      type: "accessor",
      value: (d) => {
        return ctx.occasionPlateStates[d.id]?.occasion?.occasion_name;
      },
    },

    getText: {
      type: "accessor",
      value: (d) => {
        return ctx.occasionPlateStates[d.id]?.occasion?.occasion_title?.toUpperCase();
      },
    },

    getTextContext: {
      type: "accessor",
      value: (d) => {
        return ctx.occasionPlateStates[d.id]?.occasion?.context?.toLowerCase();
      },
    },

    getTextNeedStates: {
      type: "accessor",
      value: (d) => {
        return ctx.occasionPlateStates[d.id]?.occasion?.need_state?.toLowerCase();
      },
    },

    getTextChoiceDrivers: {
      type: "accessor",
      value: (d) => {
        return ctx.occasionPlateStates[d.id]?.occasion?.choice_driver?.toLowerCase();
      },
    },
  };

  return (layerCtx, _animationTick) => {
    return new PlateLayer({
      id: "plate-layers",
      data: occasionPlatePoints,
      pickable: true,
      opacity: layerCtx.opacityCitiesAll,
      onClick: async (point) => {
        if (!layerCtx.occasionPlateStates[point.object.id]?.occasion) {
          return;
        }

        let visibleCities = layerCtx.citiesOccasionsData.filter((el) => {
          return (
            el.long <= point.object.long + 120 &&
            el.long >= point.object.long - 120 &&
            el.lat <= point.object.lat + 80 &&
            el.lat >= point.object.lat - 80
          );
        });

        layerCtx.currentOccasion = layerCtx.occasionPlateStates[point.object.id]?.occasion;

        const regionSelected = [];
        let uniqueCities = [];

        shuffle(visibleCities).forEach((city) => {
          if (!regionSelected.includes(city.region)) {
            regionSelected.push(city.region);
            uniqueCities.push(city);
          }
        });

        uniqueCities = uniqueCities.slice(0, 3);
        layerCtx.setVisibleCities(uniqueCities);

        layerCtx.visibleCities.forEach((el) => {
          const copyOccasions = el.occasions?.slice().sort((prev, next) => {
            return next.occasion_index - prev.occasion_index;
          });
          const indexSelectedOccasion = copyOccasions.findIndex((occasion) => {
            return occasion.occasion_name === layerCtx.currentOccasion.occasion_name;
          });

          if (indexSelectedOccasion >= 0) {
            const selectedOccasion = copyOccasions.splice(indexSelectedOccasion, 1);
            el.occasions = [...selectedOccasion, ...copyOccasions];
          }
        });

        layerCtx.setIsUserStopAnimation(true);
        layerCtx.setIsShowPlateLayer(false);

        const avrgLongitude = uniqueCities.reduce((total, next) => total + next.long, 0) / 3;

        const state = {
          ...layerCtx.viewState,
          longitude: avrgLongitude,
          transitionDuration: occasionTransitionDuration,
          transitionInterpolator: new FlyToInterpolator(),
          onTransitionEnd: () => {
            console.log("onTransitionEnd");
          },
        };
        layerCtx.setViewState(state);
        layerCtx.deckGl?.setProps({
          viewState: state,
        });
        await layerCtx.$router.push("/city-choices");
      },
      updateTriggers: {
        getColor: [_animationTick],
        getText: [_animationTick],
        getPosition: [_animationTick],
        all: [_animationTick],
      },
    });
  };
};
