import Hashes from "jshashes";

const SHA256 = new Hashes.SHA256();

const USERNAME_HASH = "0d488ded447814f57b9eeca7d13db596f02f56c8c345b0df823ee45b6855d9dc";
const PASSWORD_HASH = "464e773ac710b2b0bea6f28636ab665c06114139d51e472bd2897c1945577a3a";

export default {
  namespaced: true,

  state: {
    authorized: false,
    user: null,
    loginError: "",
  },

  mutations: {
    setUser(state, user) {
      state.authorized = !!user;
      state.user = user;
    },

    setLoginError(state, message) {
      state.loginError = message;
    },
  },

  actions: {
    async login(ctx, params) {
      const { username, password } = params;

      if (SHA256.hex(username) === USERNAME_HASH && SHA256.hex(password) === PASSWORD_HASH) {
        ctx.commit("setUser", { username });
      } else {
        ctx.commit("setUser", null);
        ctx.commit("setLoginError", "Email address/password do not match any existing accounts.");
      }
    },

    async logout(ctx) {
      ctx.commit("setUser", null);
    },
  },

  getters: {
    user(state) {
      return state.user;
    },

    authorized(state) {
      return state.authorized;
    },

    loginError(state) {
      return state.loginError;
    },
  },
};
