import { filterOccasion } from "@/utils/filters";
import { getOccasionNames, getOccasionCategories, getCitiesData } from "@/deck/carto";
import { getCityDetailsData } from "@/deck/carto";

export const INITIAL_ACTIVE_SLIDE = 0;

export default {
  namespaced: true,

  state: {
    isOccasionsLoading: true,
    occasionCategory: {},
    occasionTitles: [],
    filter: { context: ["all"], needStates: ["all"], choiceDrivers: ["all"] },
    selectedOccasion: {},
    filteredOccasionPoints: [],
    citiesData: [],
    occasionsInfo: [],
    cityDetails: {},
    activeSlideOccasion: {},

    occasionsCategoriesInfo: {
      context: {
        title: "context",
        data: [],
      },
      needState: {
        title: "need",
        data: [],
      },
      choiceDriver: {
        title: "passion",
        data: [],
      },
    },

    occasionsCityInfo: {
      social: {
        title: "social",
        data: [],
      },
      search: {
        title: "search",
        data: [],
      },
      events: {
        title: "events",
        data: [],
      },
      weather: {
        title: "weather",
        data: [],
      },
    },

    activeSlide: INITIAL_ACTIVE_SLIDE,
  },

  mutations: {
    setFilter(state, filter) {
      state.filter = filter;
    },

    setSelectedOccasion(state, occasion) {
      state.activeSlide = INITIAL_ACTIVE_SLIDE;
      state.selectedOccasion = occasion;
    },

    setFilteredOccasionPoints(state, points) {
      state.filteredOccasionPoints = points;
    },

    setOccasionsInfo(state, data) {
      state.occasionsInfo = data;
    },

    setOccasionsCategoriesInfo(state, data) {
      state.occasionsCategoriesInfo = data;
    },

    setOccasionsCityInfo(state, data) {
      state.occasionsCityInfo = data;
    },

    setActiveSlideOccasion(state, data) {
      state.activeSlideOccasion = data;
    },

    setActiveSlide(state, data) {
      state.activeSlide = data;
    },
  },

  actions: {
    async getOccasion(ctx) {
      try {
        const data = await Promise.all([getOccasionNames(), getOccasionCategories()]);
        ctx.state.occasionTitles = data[0];
        ctx.state.occasionCategory = {
          contexts: ["all", ...data[1].contexts.sort()],
          needStates: ["all", ...data[1].needStates.sort()],
          choiceDrivers: ["all", ...data[1].choiceDrivers.sort()],
        };
      } catch (e) {
        console.log("ERROR (getOccasion)", e);
      }

      ctx.isOccasionsLoading = false;
    },

    async getCitiesData(ctx) {
      ctx.state.isOccasionsLoading = true;

      try {
        ctx.state.citiesData = await getCitiesData();
      } catch (e) {
        console.log("ERROR (getCitiesData)", e);
      }

      ctx.state.isOccasionsLoading = false;
    },

    async getCityDetails(ctx, params) {
      ctx.state.isOccasionsLoading = true;

      try {
        const { cityId, occasionName } = params;
        ctx.state.cityDetails = await getCityDetailsData(cityId, occasionName);
      } catch (e) {
        console.log("ERROR (getCitiesDetailsData)", e);
      }

      ctx.state.isOccasionsLoading = false;
    },
  },

  getters: {
    filteredOccasion(state) {
      const {
        context: contextsFilter,
        needStates: needStatesFilter,
        choiceDrivers: choiceDriversFilter,
      } = state.filter;

      const filteredContext =
        contextsFilter.length === 0
          ? state.occasionCategory?.contexts?.filter((el) => el !== "all")
          : filterOccasion(state.occasionCategory?.contexts, contextsFilter);

      const filteredNeedStates =
        needStatesFilter.length === 0
          ? state.occasionCategory?.needStates?.filter((el) => el !== "all")
          : filterOccasion(state.occasionCategory?.needStates, needStatesFilter);

      const filteredChoiceDrivers =
        choiceDriversFilter.length === 0
          ? state.occasionCategory?.choiceDrivers?.filter((el) => el !== "all")
          : filterOccasion(state.occasionCategory?.choiceDrivers, choiceDriversFilter);

      return {
        occasionTitles: state.occasionTitles,
        contexts: filteredContext,
        needStates: filteredNeedStates,
        choiceDrivers: filteredChoiceDrivers,
      };
    },

    occasionCategories(state) {
      return state.occasionCategory;
    },

    occasionTitles(state) {
      return state.occasionTitles;
    },

    selectedOccasion(state) {
      return { allOccasions: state.occasionTitles, occasion: state.selectedOccasion };
    },

    filteredOccasionPoints(state) {
      return state.filteredOccasionPoints;
    },

    citiesData(state) {
      return state.citiesData;
    },

    filter(state) {
      return state.filter;
    },

    occasionsInfo(state) {
      return state.occasionsInfo;
    },

    occasionsCategoriesInfo(state) {
      return state.occasionsCategoriesInfo;
    },

    occasionsCityInfo(state) {
      return state.occasionsCityInfo;
    },

    cityDetails(state) {
      return state.cityDetails;
    },

    isOccasionsLoading(state) {
      return state.isOccasionsLoading;
    },

    activeSlideOccasion(state) {
      return state.activeSlideOccasion;
    },

    activeSlide(state) {
      return state.activeSlide;
    },
  },
};
