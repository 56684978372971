export const getPredefinedCitiesDetails = async () => require("./citiesDetails.json");

export const getPredefinedCitiesOccasions = async () => require("./citiesOccasions.json");

export const getPredefinedCities = async () => {
  return [
    {
      cityId: "madrid",
      name: "Madrid",
      lat: 40.2459,
      long: -3.4209,
      tz: "Europe/Madrid",
      group: "europe",
      region: "EMEA",
    },
    {
      cityId: "cannes",
      name: "Cannes",
      lat: 43.5391902,
      long: 6.974432,
      tz: "Europe/Paris",
      group: "europe",
      region: "EMEA",
    },
    {
      cityId: "london",
      name: "London",
      lat: 51.5287718,
      long: -0.2416806,
      tz: "Europe/London",
      group: "europe",
      region: "EMEA",
    },
    {
      cityId: "warsaw",
      name: "Warsaw",
      lat: 52.2330653,
      long: 20.9211122,
      tz: "Europe/Warsaw",
      group: "europe",
      region: "EMEA",
    },

    {
      cityId: "hong kong",
      name: "Hong Kong",
      lat: 22.3529808,
      long: 113.9876154,
      tz: "Asia/Hong_Kong",
      group: "asia",
      region: "ASIA",
    },
    {
      cityId: "tokyo",
      name: "Tokyo",
      lat: 35.6681819,
      long: 139.4537847,
      tz: "Asia/Tokyo",
      group: "asia",
      region: "ASIA",
    },

    {
      cityId: "atlanta",
      name: "Atlanta",
      lat: 33.7678358,
      long: -84.4906438,
      tz: "America/Virgin",
      group: "eastcost",
      region: "AMERICAS",
    },
    {
      cityId: "nashville",
      name: "Nashville",
      lat: 36.186836,
      long: -86.925329,
      tz: "America/New_York",
      group: "eastcost",
      region: "AMERICAS",
    },

    {
      cityId: "san francisco",
      name: "San Francisco",
      lat: 37.464958,
      long: -122.251652,
      tz: "America/Los_Angeles",
      group: "westcost",
      region: "AMERICAS",
    },
    {
      cityId: "puerto vallarta",
      name: "Puerto Vallarta",
      lat: 20.6409372,
      long: -105.2962834,
      tz: "Mexico/General",
      group: "westcost",
      region: "AMERICAS",
    },

    {
      cityId: "singapore",
      name: "Singapore",
      lat: 1.3143394,
      long: 103.704165,
      tz: "Asia/Singapore",
      group: null,
      region: "ASIA",
    },
    {
      cityId: "dubai",
      name: "Dubai",
      lat: 25.0763815,
      long: 54.9473013,
      tz: "Asia/Dubai",
      group: null,
      region: "ASIA",
    },
    {
      cityId: "lagos",
      name: "Lagos",
      lat: 6.5480551,
      long: 3.2839596,
      tz: "Africa/Lagos",
      group: null,
      region: "AFRICA",
    },
    {
      cityId: "mumbai",
      name: "Mumbai",
      lat: 19.0825223,
      long: 72.7411014,
      tz: "Asia/Kolkata",
      group: null,
      region: "ASIA",
    },
    {
      cityId: "johannesburg",
      name: "Johannesburg",
      lat: -26.1713505,
      long: 27.9699847,
      tz: "Africa/Johannesburg",
      group: null,
      region: "AFRICA",
    },
    {
      cityId: "buenos aires",
      name: "Buenos Aires",
      lat: -37.0564038,
      long: -64.5144893,
      tz: "America/Argentina/Buenos_Aires",
      group: null,
      region: "AMERICAS",
    },
  ];
};

export const getPredefinedCitiesDict = async () => {
  const cities = await getPredefinedCities();
  const citiesDict = {};

  cities.forEach((city) => {
    citiesDict[city.cityId] = city;
  });

  return citiesDict;
};
